body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  margin-bottom: 10px;
}

.content {
  margin-top: 100px;
  padding-bottom: 100px;
}

.navbar-brand .logo {
  background-size: contain;
  background-repeat: no-repeat;
}

.alert {
  margin: 0 1rem 0.1rem 1rem !important;
}

.navbar {
  padding: 0 10px 0 10px;
}

.Panel {
  margin-bottom: 10px;
  background-color: transparent !important;
}

.Panel .card-header {
  border-bottom-width: 0 !important;
  padding: 0px 10px;
}

.Panel .card-body {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.DataEntryField .card-body {
  border-radius: 0.25rem;
  padding: 10px !important;
}

label{
  font-size: 0.75rem;
  font-weight: bold;
  position: static;
  position: initial;
  margin-bottom: 0 !important;
}

label span {
    font-size: medium;
    font-size: initial;
    font-weight: normal;
    font-weight: initial;
}

.min-droppable-height {
  min-height: 20px;
}

.add-icon {
  cursor: pointer;
  color: #28a745;
  margin: 0 5px;
}

.refresh-icon {
  cursor: pointer;
}

.remove-icon {
  cursor: pointer;
  color: #dc3545;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2em;
}

._loading_overlay_wrapper {
  position: static !important;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input[readonly] {
  background-color: white; 
}

li.react-datepicker__time-list-item {
  height: auto !important;
  height: initial !important;
}

.big-window{
  position: fixed;
  width: calc(100% - 40px);
  max-height: calc(100% - 90px);
  top:100px;
  left: 20px;
  overflow: visible;
}

.dropZone{
  border-radius: 0.25rem;
  border: 1px dashed #ced4da;
  cursor: pointer;
  margin: 0.25rem 0;
  padding: 0.5rem;
}

.dropZone:hover{
  border-radius: 0.25rem;
  border: 1px dashed gray;
  cursor: pointer;
}

.switch-label{
  display: inline-block;
  padding: auto;
  margin: 0 10px;
}

.form-check label {
  font-size: 1em;
  font-weight: normal;
  cursor: pointer;
}

.form-check input {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  cursor: pointer;
}

.form-check {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.25rem;
  padding-left: 2rem !important;
  cursor: pointer;
}

.form-check:hover {
  border: 1px solid gray;
}


#mainHeader {
  text-align: center;
  font-size: 36px;
  color: white;
}

#main {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #6F3861;
  height: 50%;
  width: 33%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: auto;
  outline: 2px solid white;
}

.menuListItem {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
}

.menuListItemLink, .menuListItemLink:hover {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  text-decoration: none;
  cursor:  pointer !important;
}

.wordWrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}

/*Landing general*/

.landing-bg {
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.landing-container a {
  text-decoration: none;
}

.landing-container a:hover {
  text-decoration: none;
}

.landing-tile{
  margin-bottom: 2rem;
}

.landing-container .landing-tile .tile-description{
  margin: 0;
  line-height: 1;
  height: 2.3em;
  overflow: hidden;
}

.landing-container .landing-tile .tile-title{
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.landing-container .landing-tile .tile-header{
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: 0px solid transparent;
  color: darkgray;
}

.landing-container .landing-tile .tile-header *:hover{
  color: darkslategrey;
}

.landing-navbar{
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .landing-heading {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}


.background-overlay{
  background-color: rgba(0,0,0,0.5);
}

.editor{
  background-color: white;
}

.ReactTable .rt-noData{
  z-index: 0 !important;
}

.required-sign{
  color: red;
  font-size: 1rem;
}

@media (min-width: 992px) {
  .container.login-container {
    width: 350px !important;
  }
}



.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

